import './albums.scss';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { s3BucketUrl, videoTypes } from '../../config';
import { S3Service, FileService } from '../../services';

export const Albums = () => {
    const history = useHistory();
    const [albums, setAlbums] = useState([]);
    
    useEffect(() => {
        (async () => {
            const s3Albums = await S3Service.getAlbums('gallery');
            console.log(s3Albums);
            const albumNames = s3Albums.forEach(async (p) => {
                const res = await S3Service.getObjectsFromAlbum(p, 20);
                const keys = res.objs.map(obj => obj.Key);
                const posterKey = keys.filter((k) => !videoTypes.includes(FileService.getExtension(k)))[0];
                setAlbums([ ...albums, { 
                    name: p,
                    size: keys.length,
                    posterUrl: `${s3BucketUrl}${posterKey}`
                } ])
            })
            console.log(albumNames);
            setAlbums([]);
        })()
    }, [])

    return (
        <div className="albums">
                {albums.map((a) => (
                    <div 
                        className="album--preview" 
                        onClick={() => history.push(`/albums/${a.name}`)}
                    >
                        <img className="album--preview__img" alt="Descriptive album cover for Yang's Martial Arts School, Inc." src={a.posterUrl} />
                        <h5>{a.name}</h5>
                        <p>{`${a.size} items`}</p>

                    </div>)
                )}
        </div>
    )
}