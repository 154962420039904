import { Typography, IconButton } from '@material-ui/core';
import MenuIcon  from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { useContext } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import './header.scss'
import { AppContext } from '../../../contexts';
import { renderFilteredRoutes } from '../../../services';
import { routes } from '../../../config';
import YangLogo from '../../../img/yang-logo.jpeg';

export const Header = (props) => {
    const history = useHistory();
    const location = useLocation();
    const appContext = useContext(AppContext);
    
    const renderTab = (r) => {
        return (
            <div 
                key={r.name.toLowerCase()} 
                className={`header__div--tab${location.pathname.includes(r.path) ? ` header__div--selected` : ''}`} 
                onClick={() => history.push(r.path)}
            >
                <span className="header__div--tab-content">{r.name}</span>
                {(r.children?.length > 0 && 
                    <div 
                        className="header__div--tab-menu"
                    >
                        {r.children.map((rc) => (
                            <div 
                                key={rc.name.toLowerCase()} 
                                className={`header__div--tab-menu-item${location.pathname === rc.path ? ` header__div--tab-menu-item--selected` : ''}`} 
                                onClick={(e) => { e.stopPropagation(); history.push(rc.path); }}
                            >
                                {rc.display || rc.name}
                            </div>)
                        )}
                    </div>
                )}
            </div>
        );
    }

    return (
        <div className='header'>
            <div className="header__div-title" onClick={() => history.push('/home')}>
                <img alt="Sun Moo Do Logo" src={YangLogo} className="header__div-img" />
                <Typography variant="h5" className="header__div-desc">
                    Yang's Martial Arts School, Inc.
                </Typography>
                <Typography variant="h4" className="header__div-path" onClick={(e)=> { e.stopPropagation(); props.onMenuClick(e); }}>
                    {`${(() => { 
                        for (const r of routes) {
                            if(r.path === location.pathname) {
                                return r.name;
                            }
                            const child = r.children?.find((rc) => rc.path === location.pathname);
                            if(child) {
                                return `${r.name} - ${child.display ?? child.name}`;
                            }
                            if (location.pathname.startsWith(r.path)) {
                                return r.name;
                            }
                        }
                    })() || 'Home' }`}
                </Typography>
                <IconButton className="header__btn-menu" edge="start" color="inherit" aria-label="menu" onClick={(e)=> { e.stopPropagation(); props.onMenuClick(e); }}>
                    {appContext.isDrawerOpen ? <CloseIcon fontSize="large"/> : <MenuIcon fontSize="large"/>}
                </IconButton>
            </div>  
            <div className="header__div--tabs">
                {renderFilteredRoutes(renderTab, (f) => !f.doNotDisplay)}
            </div>
        </div>
    );
}