import { Class } from './Class/Class';
import ClassSchool from "../../img/class_school.png"

export const SchoolPrograms = () => (
    <Class name="School Programs" imgSrc={ClassSchool}>
        <p>Through our programs the student develops certain virtues or habits of good conduct. These include self-discipline, patience, perseverance, trust, modesty, non-violence, and self-confidence. These virtues enable the student to have "whole heart" or "whole spirit," giving him or her the capacity to control fear and anger to do difficult things, and to live better with others.</p>
        <br />
        <p>We offer personalized programs for any age group and any size. One of our instructors will come on site to the school, pre-school, or education facility and work with your students. Programs can be one off events or on going training depending on your school's needs.</p>
        <br />
        <p>Studies have shown that activities such as Martial Arts instills leadership, respect of others, and most importantly self-confidence. Other benefits include physical fitness and increased cognitive skills that make better students. To inquire about our school programs please give us a call at (215) 661-1884</p>
    </Class>
);