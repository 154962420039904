import './sass/main.scss';
import './App.scss';
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { useState, useEffect } from 'react';
import { ThemeProvider } from "@material-ui/styles";
import { 
  Footer,
  Header,
  NavDrawer,
  Routes
} from './components';
import { AppContext } from './contexts';
import { theme } from './themes/primaryTheme';


function ScrollToTop() {
  const location = useLocation();
  
  useEffect(() => {
    const el = document.getElementById(location.hash.replace('#',''));
    if (el) {
      console.log(el);
      el.scrollIntoView({ behavior: "smooth", block: "start" });
    } else {
      window.scrollTo(0, 0);
    }
  }, [location.pathname, location.hash]);

  return null;
}

function App() {
  const [ isDrawerOpen, setIsDrawerOpen ] = useState(false)
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <AppContext.Provider value={{ isDrawerOpen }}>
          <Header onMenuClick={() => setIsDrawerOpen(!isDrawerOpen)}/>
          <div className="routes">
            <NavDrawer onAppSelection={() => setIsDrawerOpen(!isDrawerOpen)}/>
            <ScrollToTop />
            <Routes className="routes"/>
          </div>
          <Footer />
        </AppContext.Provider>
      </ThemeProvider>
    </Router>
  );
}

export default App;
