import { Home } from "../components/Home/Home";
import { About } from "../components/About/About";
import { Album } from "../components/Albums/Album";
import { Albums } from "../components/Albums/Albums";
import { GrandMasterYang } from "../components/About/GrandMasterYang/GrandMasterYang";
import { Events } from "../components/About/Events/Events";
import { FAQ } from "../components/About/FAQ/FAQ";
import { Instructors } from "../components/About/Instructors/Instructors";
import { NewsLetters } from "../components/About/Newsletters/Newsletters";
import { Classes } from "../components/Classes/Classes";
import { 
    ChildrenClass, 
    AdultClass,
    SeniorsClass,
    PrivateLessons,
    CorporateTraining,
    SchoolPrograms,
    LawEnforcement,
    SelfDefense
} from "../components/Classes";
import { Contact } from "../components/Contact/Contact";
  
  export const routes = [
      { 
          name: "Home",
          path: "/home",
          component: Home
      },
      { 
          name: "About Us",
          path: "/about",
          exact: true,
          component: About,
          children: [
              { 
                  name: "Master Yang",
                  path: "/about/grandmaster",
                  component: GrandMasterYang,
                  isNested: true
              },
              { 
                  name: "Instructors",
                  path: "/about/instructors",
                  component: Instructors,
                  isNested: true
              },
            //   { 
            //       name: "Newsletters",
            //       path: "/about/newsletters",
            //       component: NewsLetters,
            //       isNested: true
            //   },
            //   { 
            //     name: "Class & Event Calendar",
            //     display: "Calendar",
            //     path: "/about/eventcalendar",
            //     component: Events,
            //     isNested: true
            //   },
              { 
                name: "Frequently Asked Questions",
                display: "FAQ",
                path: "/about/faq",
                component: FAQ,
                isNested: true
              }  
          ]
      },
      { 
          name: "Classes",
          path: "/classes",
          exact: true,
          component: Classes,
          children: [
            { 
                name: "Children",
                path: "/classes/children",
                component: ChildrenClass,
                isNested: true
            },
            { 
                name: "Adults",
                path: "/classes/adults",
                component: AdultClass,
                isNested: true
            },
            { 
                name: "Seniors",
                path: "/classes/seniors",
                component: SeniorsClass,
                isNested: true
            },
            { 
                name: "Private Lessons",
                path: "/classes/private",
                component: PrivateLessons,
                isNested: true
            },
            { 
                name: "Corporate Training",
                path: "/classes/corporate",
                component: CorporateTraining,
                isNested: true
            },
            { 
                name: "School Programs",
                path: "/classes/school",
                component: SchoolPrograms,
                isNested: true
            },
            { 
                name: "Law Enforcement",
                path: "/classes/federal",
                component: LawEnforcement,
                isNested: true
            },
            { 
                name: "Self Defense",
                path: "/classes/selfdefense",
                component: SelfDefense,
                isNested: true
            }      
        ]
      },
    //   { 
    //       name: "Videos",
    //       path: "/videos",
    //       component: () => <div>videos</div>,
    //   },
      { 
          name: "Album",
          path: "/albums/:album",
          doNotDisplay: true,
          component: Album,
      },
      { 
          name: "Gallery",
          path: "/gallery",
          component: Albums,
      },
      { 
          name: "Contact",
          path: "/contact",
          component: Contact
      }
  ]