import { useEffect } from 'react';
import './instructors.scss';
import { instructors } from '../../../config/instructors';

export const Instructors = () => {
    // const onscroll = (e) => {
    //     const curPos = window.pageYOffset || document.documentElement.scrollTop;
    //     console.log(`scrolling`, curPos, document.getElementById('Ronald Lejman'.replaceAll(' ', '_').toLowerCase())?.);
    // };
    // useEffect(() => {
    //     console.log('mount');
    //     window.addEventListener("scroll", onscroll)

    //     return () => {
    //         console.log('unmount');
    //         window.removeEventListener("scroll", onscroll);
    //     }
    // })
    return (
        <div className="instructors">
            {/* <h2 className="header--section instructors--header">Instructors</h2>
            {instructors.filter(I => I.name === "Ronald Lejman").map((i) => ( */}
                <h2 className="header--section">Instructors</h2>
                {instructors.map((i) => (
                <section id={i.name.replaceAll(' ', '_').toLowerCase()} className="instructor">
                    <img alt={`Instructor ${i.name} ay Yang's Martial Arts School, Inc.`} className="instructor--photo" src={i.image}></img>
                    <div className="instructor--info">
                        <h1 className="instructor--info__h1">{i.name}</h1>
                        <h4 className="instructor--info__h4">{i.role}</h4>
                        {i.description.toString().split('<br />').map((p) => <p className="instructor--info--p">{p}<br /></p>)}
                    </div>
                </section>
            ))}
        </div>
        
    );
}