import { Class } from './Class/Class';
import ClassSeniors from "../../img/class_seniors.jpeg"

export const SeniorsClass = () => (
    <Class name="Seniors Classes" imgSrc={ClassSeniors}>
        <p>It's Never Too Late To Start!</p>
        <br />
        <p>Whether you are looking for something to keep yourself active or have always wanted to learn Martial Arts, Yang's Martial Arts School will help you achieve your goals. We have students who have begun their training in their 70's and have now moved on to advanced belts and classes.</p>
        <br />
        <p>Sun Moo Do is an excellent form of Martial Arts for seniors who are looking to keep active. The combination of both "soft" and "hard" styles makes it easier on joints, focus on breathing techniques, and the linear movement of our disciplines emphasize your body moving in harmony. There are many benefits to beginning Martial Arts training at any age including:</p>
        <ul style={{marginLeft: '5rem'}}>
            <li>Self Defense</li>
            <li>Mental Sharpness</li>
            <li>Better Balance</li>
            <li>Increased Self Confidence</li>
            <li>Relief of Joint Pain</li>
            <li>Increased Strength and Flexibility</li>
        </ul>
        <br />
        <p>Our Senior Classes begin with a personal consultation with Master Yang to discuss the program and benefits of training as well as to answer any questions you may have. You will then have private sessions (amount varies depending on need) to become accustomed to training and catching up with fellow students in class.</p>
    </Class>
);