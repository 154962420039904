import './albums.scss';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'
import { s3BucketUrl, videoTypes } from '../../config';
import { FileService, S3Service } from '../../services';
import { ObjViewer } from './ObjViewer/ObjViewer';

export const Album = () => {
    const location = useLocation();
    const [objects, setObjects] = useState([]);
    const [album, setAlbum] = useState('');
    const [albumObj, setAlbumObj] = useState('');
    const [nextContinuationToken, setNextContinuationToken] = useState('');
    console.log(objects);

    const getAlbumObjects = async (a) => {
        const s3Gallery = []
        try {
            const res = await S3Service.getObjectsFromAlbum(`${a}`, 20, nextContinuationToken);
            // console.log(galleryXML);
            setNextContinuationToken(res.NextContinuationToken);
            for (const key of res.objs.map(obj => obj.Key))
            {
                s3Gallery.push(key)
            }
        } catch(e) {
            console.error(e);
        } finally {
            setAlbum(a);
            setObjects([ ...objects, ...s3Gallery]);
        }
    }

    const renderObjects = () => objects.map((obj, i) => {
        const src = `${s3BucketUrl}${obj}`;
        // console.log(FileService.getExtension(obj));
        const id = `albumObj_${i}`;
        const onClick = (e, isImg) => {
                if (isImg) {
                    setAlbumObj(src);
                } 
                document.querySelectorAll('video').forEach((el) => {
                    // console.log(el);
                    // (el as HTMLVideoElement).paused
                    if(el.id !== e.target.id && !el.paused) {
                        el.pause();
                    }
                });
        };

        if (videoTypes.includes(FileService.getExtension(obj))) {
            return (
                <video
                    id={id}
                    key={id}
                    className="albums--obj album--video"
                    src={`${src}#t=0.001`}
                    type="video/mov"
                    controls
                    playsInline
                    preload="metadata"
                    onClick={onClick}
                />
            );
        }
        return (
            <img
                id={id}
                key={id}
                className="albums--obj"
                src={src}
                alt="Descriptive image for Yang's Martial Arts School, Inc."
                onClick={(e) => onClick(e, true)}
            />
        );
    });

    useEffect(() => {
        const routeAlbum = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
        getAlbumObjects(routeAlbum)
    }, [location.pathname]);


    return (
        <div className="album">
            <h2 className="header--section">{album}</h2>
            <div className="albums">
                {renderObjects()}
            </div>
            {nextContinuationToken && (
                <button className="btn" onClick={() => getAlbumObjects(album)}>
                    Load More Items
                </button>
            )}
            {albumObj && <ObjViewer src={albumObj} onClose={() => {console.log('closing'); setAlbumObj(undefined)}}/>}
        </div>
    )
}