import './class-card.scss';

export const ClassCard = (props) => {
    return (
        <div className="card" onClick={props.onClick}>
            <div className={`class-card--header ${props.headerClass}`}>
                <h4>{props.header}</h4>
            </div>
            <div className="class-card--descriptors">
                {props.descriptors?.map((d, i) => <p key={`${i}`}>{d}</p>)}
            </div>
            <p className="class-card--info">
                {props.info}
            </p>
        </div>
    );
}