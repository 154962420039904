export const Icon = (props) => {
    return  (
        <svg  
            focusable="false" 
            aria-hidden="true"
            viewBox={props.viewBox} 
            className={`MuiSvgIcon-root${props.className ? ` ${props.className}` : ''}`}
        >
            {props.children}
        </svg>
    )
}