import './obj-viewer.scss';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

export const ObjViewer = (props) => {
    const renderObj = () => {
        const id = `obj_${props.src}`;
        return (
            <img
                id={id}
                className="obj-viewer--obj"
                src={props.src}
                alt="Enlarged media for Yang's Martial Arts School"
            />
        );
    }

    return (
        <div className="obj-viewer">
            <IconButton className="obj-viewer--close" edge="start" color="inherit" aria-label="menu" onClick={props.onClose}>
                <CloseIcon fontSize="large" />
            </IconButton>
            {renderObj()}
        </div>
    );
}