import './home.scss';
import { useHistory } from "react-router-dom";
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import DirectionsRun from '@material-ui/icons/DirectionsRun';
import InsertEmoticon from '@material-ui/icons/InsertEmoticon';
import CenterFocusWeak from '@material-ui/icons/CenterFocusWeak';
import { RelieveStress } from '../Icons/RelieveStress';
import { Flexibility } from '../Icons/Flexibility';
import { Goal } from '../Icons/Goal';
import EmojiPeople from '@material-ui/icons/EmojiPeople';
import SunMooDo from '../../img/SunMooDo.png';
import MasterYang from '../../img/masteryang.png';
import { ClassCard } from '../Classes';
import { LinkButton, InfoCard, Quote } from '../Common';

export const Home = () => {
    const history = useHistory();

    return (
        <div className="welcome-main">
            <h1 className="welcome-main__div">
                <span className="welcome-main--title">Yang's Martial Arts School, Inc.</span>
                <span className="welcome-main--sub">Begin training your mind and body</span>
            </h1>
            <section className="welcome-desc">
                <h2 className="header--section">Premium training for dedicated individuals</h2>
                <div className="welcome-desc--container">
                    <div className="welcome-desc--composition">
                    <img className="welcome-desc--composition__photo  welcome-desc--composition__photo--p2" src={MasterYang} alt="Grand Master Yang in ready stance" />
                        <img className="welcome-desc--composition__photo  welcome-desc--composition__photo--p1" src={SunMooDo} alt="Sun Moo Do Logo" />
                    </div>
                    <div className="welcome-desc--text">
                        <h3>World Sun Moo Do Federation</h3>
                        <p className="">
                            We are the premier traditional Korean Martial Arts School in the Philadelphia Area. 
                            Under the instruction of Grand Master Bong Pil Yang, we have been in North Wales for over 35 years. 
                            At Yang's Martial Arts School, we practice Sun Moo Do, which combines the arts of Tae Kwon Do, Hap Ki Do, Kung Jung Moo Sul, Gumdo, Meditation and Ki Training. 
                            Grand Master Yang has always believed in the balance of martial arts training: The“hard” and the “soft” as well as the spiritual elements of his art. 
                            Grand Master Yang’s focus on the balance between mind and body ensures that each student becomes a complete person and martial artist.
                        </p>
                        <LinkButton linkText="More About Us" path='/about' className="link-button--absolute"/>
                    </div>
                </div>
            </section>
            <section className="welcome-info">
                <h2 className="header--section">Benefits</h2>
                <div className="info-card--container">
                    <InfoCard id= 'infoCard_1' icon={<FavoriteBorder className="info-card--icon"/>} headerText= "Better Cardio-vascular Health" />
                    <InfoCard id= 'infoCard_2' icon={<Flexibility className="info-card--icon"/>} headerText= "Better Fitness and Flexibility" />
                    <InfoCard id= 'infoCard_3' icon={<EmojiPeople className="info-card--icon"/>} headerText= "Improved Self-Confidence" />
                    <InfoCard id= 'infoCard_4' icon={<InsertEmoticon className="info-card--icon"/>} headerText= "Positive Attitude" />
                    <InfoCard id= 'infoCard_5' icon={<DirectionsRun className="info-card--icon"/>} headerText= "Better Coordination" />
                    <InfoCard id= 'infoCard_6' icon={<Goal className="info-card--icon"/>} headerText= "Self-Discipline" />
                    <InfoCard id= 'infoCard_7' icon={<CenterFocusWeak className="info-card--icon"/>} headerText= "Stronger Concentration" />
                    <InfoCard id= 'infoCard_8' icon={<RelieveStress className="info-card--icon" />}headerText= "Relieve Stress" />
                </div>
            </section>
            <section className="welcome-classes">
                <h2 className="header--section">Classes</h2>
                <Quote>
                    The study and practice of the martial arts is the best thing any human being can add to his or her life. 
                    What is the most powerful thing to improve oneself and to become a true winner and success in life? Definite, powerful, positive goals put into action.
                </Quote>
                <div className="class-card--container">
                    <ClassCard
                        onClick={() => history.push('/classes/children')}
                        headerClass="class-card--header-1"
                        header="Children classes"
                        descriptors={[
                            "Ages 4-12",
                            "Control fear and anger",
                            "Love for friends",
                            /* "Respect and devotion to parents, honor and respect for elders", */
                            "Love and honor for your country",
                            "Develop and learn to live by code of good conduct and high principles"
                        ]}
                    />
                    <ClassCard
                        onClick={() => history.push('/classes/adults')}
                        headerClass="class-card--header-2"
                        header="Adult classes"
                        descriptors={[
                            "Ages 15+",
                            "No prior experience required",
                            "Lose weight/Stay in shape",
                            "Have a positive impact on your daily life",
                            "Taught multiple disciplines by a true Grand Master",
                            "Learn to live a healthy stress free lifestyle through Martial Arts"
                        ]}
                    />
                    <ClassCard
                        onClick={() => history.push('/classes/private')}
                        headerClass="class-card--header-3"
                        header="Private lessons"
                        descriptors={[
                            "All ages welcome",
                            "Flexible scheduling",
                            "One on one training focused on your needs",
                            "Group private lessons available"
                        ]}
                    />
                </div>
                <LinkButton linkText="View all classes" path='/classes' className="welcome-classes--link"/>
            </section>
        </div>
    );
}