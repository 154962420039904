import './contact.scss';
import { useEffect, useState } from 'react';
import { address, telephone, mail } from '../../config';
import { PinDrop } from '../Icons/PinDrop';
import { Phone } from '../Icons/Phone';
import { Mail } from '../Icons/Mail';
import YangLogo from '../../img/yang-logo.jpeg';

export const Contact = () => {
    const { streetAddress, city, state, zip } = address;

    useEffect(() => {
        const frmEmail = document.getElementById('frmEmail');
        if (frmEmail) {
            frmEmail.addEventListener('keypress', (e) => {
                if (e.key === 'Enter') {
                    frmEmail.checkValidity();
                    document.getElementById('btnEmail')?.click();
                }
            });
        }

        const el1 = document.getElementById('contact');

        const mapId = 'script_map';
        if (!document.getElementById(mapId)) {
            const getMap = document.createElement('script');
            getMap.id = mapId;
            getMap.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&callback=initMap&libraries=&v=weekly`;
            getMap.async = true;
            el1.appendChild(getMap);
        }
    }, [])

    return (
        <div id="contact" className="contact">
            <h2 className="header--section">{'Come Visit Us'}</h2>
            <div className="contact--container">
                <div>
                    <div id="divContactInfo"className="contact--info__container info-card">
                        <div className="contact--info__img-container">
                            <img alt="Sun Moo Do Logo" src={YangLogo} className="contact--info__img" />
                        </div>
                        <div>
                            <div id="divAddress" className="contact--info">
                                <PinDrop className="contact__icon"/>
                                <a className="contact__a" target="_blank" href="https://www.google.com/maps/place/200+Pennbrook+Pkwy,+Lansdale,+PA+19446/@40.227512,-75.2845388,17z/data=!3m1!4b1!4m5!3m4!1s0x89c6a3ba8418db6b:0xd553b45fe487249e!8m2!3d40.227512!4d-75.2823501">
                                    <span>{`${streetAddress}`}</span>
                                    <span>{`${city}, ${state} ${zip}`}</span>
                                </a>
                            </div>
                            <div id="divAddress" className="contact--info">
                                <Phone className="contact__icon"/>
                                <a className="contact__a" href={`tel:${telephone.replace(' ', '').replace('(', '').replace(')', '').replace('-', '')}`}>{telephone}</a>
                            </div>
                            <div id="divAddress" className="contact--info">
                                <Mail className="contact__icon"/>
                                <a className="contact__a" href={`mailto:${mail}?subject=Inquiry: Yang's Martial Arts&body=I am interested in Yang's Martial Arts and would like to make an inquiry.`}>{mail}</a>
                            </div>
                        </div>
                    </div>
                    {/* <div>
                        <h3>Do Jang Hours</h3>
                        <p>Thursday	9AM–8PM</p>
                        <p>Friday	9AM–8PM</p>
                        <p>Saturday	10AM–12:45PM</p>
                        <p>Sunday	Closed</p>
                        <p>Monday	9AM–8PM</p>
                        <p>Tuesday	9AM–8PM</p>
                        <p>Wednesday	9AM–8PM</p>
                    </div> */}
                </div>
                <div id="map" className="contact--map"></div>
            </div>
            {/* <div id="divEmail" className="contact--container">
                <h2 className="header--section">Reach out to us</h2>
                <p>We look forward to hearing from you. We will respond to your message as soon as possible.</p>
                <form id="frmEmail" className="contact__form" onSubmit={submitForm}>
                    {renderInput({ placeholder: "A reply email so that we can get back to you", label: "E-Mail", onChange: (e) => { e.target.value = e.target.value.trim(); setForm({ ...form, ...{ email: e.target.value } }) }})}
                    {renderInput({ placeholder: "Subject", label: "Subject", onChange: (e) => setForm({ ...form, ...{ subject: e.target.value } }) })}
                    {renderInput({ placeholder: "Message", label: "Message", multiline: true, onChange: (e) => setForm({ ...form, ...{ message: e.target.value } }) })}
                    <button id="btnEmail" className="btn contact__btn" type="submit">Submit</button>
                </form>
            </div> */}
        </div>
    );
}