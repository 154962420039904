import './link-button.scss';
import { useHistory } from "react-router-dom";
import ArrowForward from '@material-ui/icons/ArrowForward';

export const LinkButton = (props) => {
    const history = useHistory();
    return (
        <span className={`link-button ${props.className ? props.className : ""}`}  onClick={() => history.push(props.path)}>
            {props.linkText} <ArrowForward />
        </span>
    );
}