import { Icon } from './Icon';

export const Flexibility = (props) => {
    return  (
        <Icon 
            className={props.className}
            viewBox="0 0 100 100"
        >
            <path d="M94.97,24.478c-0.215-0.903-1.525-1.378-2.983-1.082c0,0-18.15,3.838-18.583,4.174c0,0-17.558,0.026-17.756,0.065  c-3.733,0.677-8.791,0.514-11.795-0.01c0,0-16.695-3.186-16.761-3.194c-0.489-0.408-19.221-2.542-19.221-2.542  c-1.48-0.15-2.737,0.452-2.862,1.372c-0.121,0.894,0.871,1.788,2.287,2.063c0,0,18.269,3.851,18.705,3.995l14.535,4.819  c-2.58,7.137-4.785,15.331-4.427,22.006l-19.493,1.065c-2.614,0.143-3.924,2.267-3.68,4.554c0.608,5.693-2.213,22.795-2.213,22.795  c-0.16,1.646,0.993,3.105,2.576,3.258c1.534,0.149,2.918-0.655,3.139-2.243L19.212,66h19.402c0.425,0,11.052,0.107,11.56,0.372  L70.85,77.06c0.222,0.116,17.119,11.3,17.119,11.3c0.874,0.573,1.942,0.692,2.85,0.354c2.115-0.786,2.319-3.609,0.367-5.061  c0,0-16.046-12.471-16.672-12.887c0,0-18.947-12.469-19.355-12.528c-3.817-5.728-2.588-12.607,1.012-23.422  c0.255,0.032,17.717-2.224,17.717-2.224c0.645-0.085,19.009-5.836,19.009-5.836C94.28,26.343,95.178,25.355,94.97,24.478z"></path>
            <circle cx="47.901" cy="17.797" r="6.691"></circle>
        </Icon>
    );
}