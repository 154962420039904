import { Class } from './Class/Class';
import ClassPrivate from "../../img/class_private.png"

export const PrivateLessons = () => (
    <Class name="Private Lessons" imgSrc={ClassPrivate}>
        <p>Yang's Martial Arts School offers private lessons for students who can't make class for a number of reasons.</p>
        <br />
        <p>Whether our current class schedule doesn't match up with your availability, you wish to benefit from one on one instruction, or you may not be comfortable training with other students we will work with you to put together the appropriate training program. We also offer private lessons for an entire group, feel free to get friends and family together to learn Self Defense, Sun Moo Do, or Ki Meditation.</p>
        <br />
        <p>For private lessons you begin with a private consultation with Master Yang to discuss your goals and needs. From there Master Yang and his instructors will develop the right program to get you on your way to studying Martial Arts. For more information give us a call at (215) 661-1884.</p>
    </Class>
);