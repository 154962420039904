import './footer.scss'
import { IconButton } from '@material-ui/core';
import { Facebook, Twitter, YouTube, Copyright } from '@material-ui/icons';
import { LinkButton } from '../../Common';
import { address, telephone, mail } from '../../../config';
import YangLogo from '../../../img/yang-logo.jpeg';

export const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-row footer-information">
                <div className="footer-column footer-information--about">
                    <h2>About us</h2>
                    <p>
                        Established in 1978, we have remained in the North Penn area while many other schools have come and gone. 
                        We are a school that is now teaching the next generation - some of our early students are now bringing their children, and even grandchildren, back to our school.
                        <LinkButton linkText="Read More" path="/about" className="footer-information--about__btn"/>
                    </p>
                    <br />
                    <h4>Address:</h4>
                    <a className="contact__a footer--address" target="_blank" href="https://www.google.com/maps/place/200+Pennbrook+Pkwy,+Lansdale,+PA+19446/@40.227512,-75.2845388,17z/data=!3m1!4b1!4m5!3m4!1s0x89c6a3ba8418db6b:0xd553b45fe487249e!8m2!3d40.227512!4d-75.2823501">
                        <span>{`${address.streetAddress}`}</span>
                        <span>{`${address.city}, ${address.state} ${address.zip}`}</span>
                    </a>
                    <h4>Telephone:</h4>
                    {/* {telephone} */}
                    <a className="contact__a" href={`tel:${telephone.replace(' ', '').replace('(', '').replace(')', '').replace('-', '')}`}>{telephone}</a>
                    <h4>Email:</h4>
                    {/* {telephone} */}
                    <a className="contact__a" href={`mailto:${mail}?subject=Inquiry: Yang's Martial Arts&body=I am interested in Yang's Martial Arts and would like to make an inquiry.`}>{mail}</a>
                </div>
                {/* TODO
                    <div className="footer-column">
                        Latest Tweets
                    </div> 
                */}
                {/* TODO
                    <div className="footer-column">
                        <h2>Newsletter Signup</h2>
                    </div> 
                */}
            </div>
            <div className="footer-row footer-copyright">
                <div className="footer-column footer-copyright--logo">
                    <img alt="Sun Moo Do Logo" src={YangLogo} className="footer-copyright--logo__img" />
                    <h3 className="footer-copyright--logo__typ">
                        Yang's Martial Arts School, Inc.
                    </h3>
                </div>

                <div className="footer-column footer-copyright--info">
                    <Copyright />&nbsp;2021 - {(new Date()).getFullYear()}
                </div>

                <div className="footer-column footer-copyright--media">
                    <IconButton aria-label="Facebook" href="https://www.facebook.com/YangsMartialArtsSchool">
                        <Facebook />
                    </IconButton>
                    <IconButton aria-label="Twitter" href="http://twitter.com/YangMartialArts">
                        <Twitter />
                    </IconButton>
                    <IconButton aria-label="YouTube" href="https://www.youtube.com/channel/UC3hQ5nKA7EsXKqGIkSVC64Q">
                        <YouTube />
                    </IconButton>
                </div>
            </div>
        </footer>
    );
}