import { Class } from './Class/Class';
import ClassChildren from "../../img/class_children.png"

export const ChildrenClass = () => (
    <Class name="Children Classes" imgSrc={ClassChildren}>
        <p>At Yang's Martial Arts School students in our Children's Class (Ages 4-12) learn the art of Mental Self Defense. Bullying is the No. 1 social issue in school today. We can teach our children to understand to resolve conflicts nonviolently through a special program that combines the disciplines of education, psychology and the seemingly contradictory practice of martial arts.</p>
        <br />
        <p>First, the martial artist develops certain virtues or habits of good conduct. These include self-discipline, patience, perseverance, trust, modesty, non-violence, and self-confidence. These virtues enable the martial artist to have "whole heart" or "whole spirit," giving him the capacity to control fear and anger to do difficult things, to live better with others, etc.</p>
        <br />
        <p>Second, the martial artist develops and lives by a code of good conduct and high principles. This code includes honesty, loyalty, unselfishness and honor.</p>
        <br />
        <p>Third, the martial artist develops and maintains an attitude of respect for self, others and country. This begins with self-respect, but also involves respect and devotion to parents, honor and respect for elders because of their age and experience, love for friends and those younger, and also love and honor for your country.</p>
        <br />
        <p>Fourth, the martial artist sets certain physical goals. These include power, strength, endurance, speed agility, balance and equilibrium , poise, breath control, flexibility and concentration. These are gained through rigorous training in self-defense and fighting techniques. With dedication and hard work these physical abilities come, and with them comes the ability to defend yourself. your family and your country. But it is very important to note that these abilities should not be misused. They are not to be used to harm others unnecessarily, to bully others, or to wrongfully gain power over others.</p>
        <br />
        <p>Fifth, the martial artist seeks to develop and maintain "Ki", which can be transferred as universal energy. Ki can be described as a unique life energy that ties the body and mind. It is a mysterious and amazing part of your life force. Through the harmony of body, mind and spirit, it allows us to be strong in both physical and spiritual ways. Those who can maintain and apply this energy have great capabilities of self-defense and the ability to perform amazing feats of courage and self- control.</p>
        <br />
        <p>Thus, you can see that the martial arts are way of life that require both philosophy and physical training. A person who has only the philosophy may be weak and find that he cannot easily live according to this philosophy. A person who knows only the techniques lacks understanding and a sense of direction. Thus, like yin-yang, the harmonious blending of the two opposites by complimentary halves (male/female, light/dark, hot/cold, etc.) forms a powerful, natural whole.</p>
        <br />
        <p>Finally, no other system incorporates a complete health and exercise program with self­ discipline and adds in self-defense and a clear goal system to produce a positive mental attitude and self-motivation. It leads us down a path of self-improvement towards a successful life, and teaches us to put our ideas into action to change ourselves and the world around us.</p>
    </Class>
);