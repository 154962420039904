import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { Home } from '../components';
import { renderRoutes } from "../services";


export const Routes = () => (
  <Switch>
    {renderRoutes((r) => {
      const { isNested, children, ...params} = r;
      return <Route key={r.name.toLowerCase()} {...params} />;
    })}
    <Route component={Home} path="/"></Route>
  </Switch>
);