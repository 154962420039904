import './info-card.scss';

export const InfoCard = (props) => {
    return (
        <div id={props.id} className="info-card">
            {props.icon}
            <h3 className="info-card--header">{props.headerText}</h3>
            <p className="info-card--text">{props.text}</p>
        </div>
    );
}