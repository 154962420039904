import { Divider, Drawer, List, ListItem, ListItemText } from '@material-ui/core';
import { Link } from "react-router-dom";
import { Fragment, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import './nav-drawer.scss'
import { AppContext } from '../../../contexts';
import { renderFilteredRoutes } from "../../../services";


export const NavDrawer = (props) => {
    const appContext = useContext(AppContext);
    const location = useLocation();

    const renderListItem = (r) => {
        const listItem = (li) => (
            <Fragment>
                <ListItem id={li.path} button component={Link} to={li.path} onClick={props.onAppSelection} className={`navdrawer-listitem ${li.isNested ? 'navdrawer-listitem-nested' : ''}${li.path === location.pathname ? ' navdrawer-listitem-selected' : '' }`}>
                    <ListItemText classes={{primary: "navdrawer-listitem-text"}} primary={li.name} />
                </ListItem>
                {!li.isNested && <Divider id={`${li.path}-divider`}/>}
            </Fragment>
        );

        return (
            <Fragment key={r.name.toLowerCase()}>
                {listItem(r)}
                {r.children?.map((rc) => listItem(rc))}
            </Fragment>
        );
    }

    return (
        <Drawer 
            anchor="right"
            open={appContext.isDrawerOpen}
            className="navdrawer"
        >
            <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                className='navdrawer-list'
            >
                {renderFilteredRoutes(renderListItem, (f) => !f.doNotDisplay)}
                <div id={'liNavDrawerSpacing'} className={`navdrawer-listitem-spacing`} />
            </List>
        </Drawer>
    );
}
