import { Class } from './Class/Class';
import ClassAdults from "../../img/class_adults.png"

export const AdultClass = () => (
    <Class name="Adult Classes" imgSrc={ClassAdults}>
        <p>Have you always wanted to learn Martial Arts?</p>
        <br />
        <p>Looking for a fun way to stay in shape or relieve stress?</p>
        <br />
        <p>Our classes are designed to benefit anybody at any age. Whether you studied Martial Arts years ago as a child or you want to learn now. Our instructors, many of whom started training later in life, will get you up to speed and on your way to living a healthy stress free lifestyle through Martial Arts.</p>
        <br />
        <p>Many of our students are looking for activities to help them lose weight, stay in shape, and/or have a positive impact on their daily life. Some of our students have a desire to train for advanced belts or to help them move on to other disciplines including Mixed Martial Arts. Sun Moo Do taught at Yang's Martial Arts gives you the opportunity to take your training and apply it in multiple areas of life. Tae Kwon Do focuses mainly on kicks and punches, Hap Ki Do focuses on flowing movements and harmonizing with an opponent, Meditation and Ki training focuses on calming the mind and body while focusing your energy.</p>
        <br />
        <p>Any Martial Arts studio or school can offer a workout, but being taught multiple disciplines by a true Grand Master can impart the life altering benefits that have been passed down through the centuries.</p>
    </Class>
);