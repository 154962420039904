import './quote.scss';

export const Quote = (props) => {
    return (
        <div className={`quote ${props.className ? ` ${props.className}` : ''}`}>
            <svg className="quote__svg" role="presentation" viewBox="0 0 33 24">
                <path d="M10.5 0C4.7 2.5 0 8 0 14.5 0 19.4 2.9 24 8.2 24c3.5 0 6.3-2.7 6.3-6.3           0-2.7-2.2-5.2-5-5.2-2.7 0-4.6 1.3-5.2 4-.8-1-.9-2.6-.9-3.8C3.5 8 7 3.9           10.9 1.8L10.5 0zm18.4 0c-5.7 2.5-10.5 8-10.5 14.5 0 4.9 2.9 9.5 8.3 9.5           3.5 0 6.3-2.7 6.3-6.3 0-2.7-2.2-5.2-5-5.2-2.7 0-4.6 1.3-5.2           4-.8-1-.9-2.6-.9-3.8 0-4.6 3.5-8.7 7.4-10.7l-.4-2z"></path>
            </svg>
            <p>
                {props.children}
            </p>
        </div>
    );
}
