import './classes.scss';
import { useHistory } from "react-router-dom";
// import ChildrenClasses from '../../img/classes_children.png'
import MainClasses from '../../img/classes_main.jpeg'
import { ClassCard } from './ClassCard/ClassCard';

export const Classes = () => {
    const history = useHistory();

    return (
        <div className="classes">
            <section >
                <h2 className="header--section">{'Classes'}</h2>
                <h3>It's Never Too Late To Start! Stay In Shape, Lose Weight, Relieve Stress through Martial Arts Training</h3>
                {/* <h1 className="welcome-main__div classes-main__div">
                    <span className="welcome-main--title">Classes</span>
                    <span className="welcome-main--sub">It's Never Too Late To Start! Stay In Shape, Lose Weight, Relieve Stress through Martial Arts Training</span>
                </h1> */}
                <img class="class--img" src={MainClasses}></img>
            </section>
            <h2 className="header--section">
                The <u>complete</u> martial arts program
            </h2>
            <h3>
                Let Grand Master Bong Pil Yang (45 years experience) teach you traditional Korean martial arts
            </h3>
            <div className="class-card--container">
                <ClassCard
                    onClick={() => history.push('/classes/adults')}
                    headerClass="class-card--header-2"
                    header="Adult classes"
                    info="Our classes are designed to benefit anybody at any age. Whether you studied Martial Arts years ago as a child or you want to learn now. Our instructors, many of whom started training later in life, will get you up to speed and on your way to living a healthy stress free lifestyle through Martial Arts."
                />
                <ClassCard
                    onClick={() => history.push('/classes/children')}
                    headerClass="class-card--header-1"
                    header="Children classes"
                    info="No other system incorporates a complete health and exercise program with self­ discipline and adds in self-defense with a clear goal system to produce a positive mental attitude and self-motivation. It leads us down a path of self-improvement towards a successful life, and teaches us to put our ideas into action to change ourselves and the world around us."
                />
                <ClassCard
                    onClick={() => history.push('/classes/seniors')}
                    headerClass="class-card--header-4"
                    header="Seniors classes"
                    info="Whether you are looking for something to keep yourself active or have always wanted to learn Martial Arts, Yang's Martial Arts School will help you achieve your goals. We have students who have begun their training in their 70's and have now moved on to advanced belts and classes."
                />
                <ClassCard
                    onClick={() => history.push('/classes/private')}
                    headerClass="class-card--header-3"
                    header="Private lessons"
                    info="Whether our current class schedule doesn't match up with your availability, you wish to benefit from one on one instruction, or you may not be comfortable training with other students we will work with you to put together the appropriate training program. We also offer private lessons for an entire group, feel free to get friends and family together to learn Self Defense, Sun Moo Do, or Ki Meditation."
                />
                <ClassCard
                    onClick={() => history.push('/classes/corporate')}
                    headerClass="class-card--header-5"
                    header="Corporate Training"
                    info="Yang’s Martial Arts School offers a Stress Reduction Program for companies in the North Penn area. This program combines the elements of special breathing, meditation, and stretching exercises with Ki training to provide employees with the ability to effectively deal with pressures both inside and outside the workplace."
                />
                <ClassCard
                    onClick={() => history.push('/classes/school')}
                    headerClass="class-card--header-6"
                    header="School Programs"
                    info="We offer personalized programs for any age group and any size. One of our instructors will come on site to the school, pre-school, or education facility and work with your students. Programs can be one off events or on going training depending on your school's needs."
                />
                <ClassCard
                    onClick={() => history.push('/classes/federal')}
                    headerClass="class-card--header-8"
                    header="Law Enforcement"
                    info="We work with local law enforcement agencies and security contractors on continuing education and teaching of new techniques."
                />
                <ClassCard
                    onClick={() => history.push('/classes/selfdefense')}
                    headerClass="class-card--header-7"
                    header="Self Defense"
                    info="Knowing how to protect yourself can be a real life saver. Self-defense is not karate or martial arts training, it does not require years of study to perfect. You don't have to be an athlete to learn how to defend yourself. Self-defense skills are not only for those few times in your life you have a scary encounter."
                />
            </div>
        </div>
    );
}