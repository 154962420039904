import AboutRonDesc from '../img/about_ron_desc.png'
import AboutJohnDesc from '../img/about_johnhubbard_desc.png'
import AboutTroyDesc from '../img/about_troy_desc.png'

export const instructors = [
    {
        "image": AboutRonDesc,
        "href": "/about/instructors#ronald_lejman",
        "name": "Ronald Lejman",
        "role": "Master Instructor (4th Degree Black Belt)",
        "shortDescription": "Currently a Third Dan Black Belt, Ron is very serious and motivated in his training, and in fact in all of his endeavors.",
        "description": "Currently a Third Dan Black Belt, Ron is very serious and motivated in his training, and in fact in all of his endeavors…it is in his character. Ron has been a great help to Master Yang and to the School, from being a leader in the Black Belt Club over the past 9 years to helping with many of the school's activities and charitable events. Ron is the Vice President of the American Delegation to the 21st Century America-Korea-China Leaders Society and through this organization has done much to spread the values of various cultures throughout the local community. He spends the rest of his time supporting his two children, Noah and Cala, in their school and extracurricular activities. As all of you parents in the audience know, supporting two teenagers with school and sports can be an all-encompassing task."
    },
    {
        "image": AboutJohnDesc,
        "href": "/about/instructors#dr._john_hubbard",
        "name": "Dr. John Hubbard",
        "role": "Master Instructor (4th Degree Black Belt)",
        "shortDescription": "Currently a Third Dan Black Belt, John joined Yang’s Martial Arts School as a green belt in April 2005. John internalizes Grand Master Yang’s focus on the core principles.",
        "description": "Currently a Third Dan Black Belt, John joined Yang’s Martial Arts School as a green belt in April 2005. Returning to martial arts training at the age of 48 has been challenging, but also extremely rewarding. Martial arts training has help John maintain good physical conditioning and to be a more complete individual. He internalizes Grand Master Yang’s focus on the core principles of physical, spiritual, and mental conditioning, which he describes as “harmony and balance, avoiding extremes.”<br /><br />John is involved in the Doylestown Kiwanis, the 21st Century America-Korea-China Leaders Society, and St. Mary’s Villa for Children, and Doylestown Hospital. He is an Advisory Board Member and Fundraising Chair for the Children’s Brain Tumor Foundation, a board member for the North Penn Korean Community Service Center, and is the President of the American Delegtion of the 21st Century America-Korea-China Leaders Society. John Lives in Doylestown with his wife of 31 years, Jeanne."
    },
    {
        "image": AboutTroyDesc,
        "href": "/about/instructors#troy_dougherty",
        "name": "Troy Dougherty",
        "role": "Master Instructor (4th Degree Black Belt)",
        "shortDescription": "I enjoy teaching classes, especially children. I love to see the self confidence and strength that even the most shy and introverted child gains through training in martial arts.",
        "description": "I am a small animal veterinarian and owner of All Creatures Veterinary Clinic in Lower Gwynedd. I am married with two children, Jack (12) and Flynn (7). I enjoy bicycling, motorcycling, swimming in the ocean and any outdoor activities with my family. My children and I also attend many Philadelphia sporting events together.<br /><br />I started training in martial arts when I joined Yang’s Martial Arts School in May of 2009. Through training I have benefited both physically and mentally. I have learned to control stress and anxiety in day to day life through martial arts. Most importantly I have learned to appreciate everyone and everything around me so much more than I did before.<br /><br />I enjoy teaching classes, especially children. I love to see the self confidence and strength that even the most shy and introverted child gains through training in martial arts"
    }
]