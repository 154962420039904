import './grand-master-yang.scss';
import { Quote } from '../../Common';
import GrandMasterYangPic from '../../../img/grand-master-yang.png'
import GrandMasterYangForm from '../../../img/grand-master-yang-form.png'
import GrandMasterYangSword from '../../../img/sword.png'

export const GrandMasterYang = () => {
    return (
        <div className="grand-master-yang--main">
            <h2 className="header--section">Grand Master Bong Pil Yang</h2>
            <section className="grand-master-yang">
                <img alt="Grand Master Yang with Korean flag in background" className="grand-master-yang--img" src={GrandMasterYangPic}></img>
                <p className="grand-master-yang--txt">
                    Master Yang was born in the village of Hamyang City, South Korea, on November 5th 1951. 
                    This was as rural area of South Korea and his and Master Yang’s family eventually decided to move to the capital city Seoul, South Korea where the family could have more opportunities found in a larger metropolitan city.
                    <br />
                    <br />
                    When Master Yang was 13 years old he began his martial arts training with his first martial arts instructor, Master Soon Sik Choi.
                    Master Choi was a very traditional instructor of Kung Jung Mu Sul, the martial art of Korea’s Royal Court military personnel. 
                    The Art traces its roots to the Yi Dynasty period in Korean history. 
                    The Art focuses on triangular movements and the use of empty hand as well as proficiency with various traditional weapons.
                </p>
            </section>
            <section className="grand-master-yang">
                <Quote className="grand-master-yang--quote">
                    In 1978, Master Yang traveled to the United States and four years later founded Yang’s Martial Arts School. Over the years Grand Master Yang has had more than 3500 students and has graduated only 136 Black Belts. In 1990, Master Yang integrated all the elements of his martial training into an integrated system he calls “Sun Moo Do”. The system incorporates the elements of Hapkido, Tae Kwon Do, and Kung Jung Mu Sul, and Ki Meditation and breathing techniques into a balanced and comprehensive martial arts system.
                </Quote>
            </section>
            <section className="grand-master-yang">
                <img alt="Grand Master Yang in funky stance" className="grand-master-yang--img" src={GrandMasterYangForm}></img>
                <p className="grand-master-yang--txt">
                    Master Yang began teaching classes for Master Choi, where he continued to advance his technique and impart his knowledge to students of lesser rank. 
                    As Master Yang expanded his skills he embarked on training with other grand masters of Kung Jung Mu Sul. 
                    Great Master Jung Sun Son has a long history of martial arts training in Kung Jung Mu Sul and trained under Great Master Han Chul Lee who was a practitioner of Buddhist martial arts (Bul Moo Do) as well as Kung Jung Mu Sul. 
                    Great Master Jung Sun Son currently lives in Barcelona, Spain where he applies his knowledge of martial arts and Ki training to help treat and heal people with an array of health maladies. 
                    Grand Master Choi also lives in Barcelona, Spain and continues to teach Kung Jung Mu Sul.
                    <br />
                    <br />
                    Master Yang learned and trained on many unique forms and techniques at the age of 22 under Great Master Jung Sun Son. 
                    These techniques included Chul Sa Chang (Iron Palm), Pyong Su Bub, and Palgwe Jang (Praying Mantis) techniques. 
                    These traditional forms employ animal movements with Ki power and precision. 
                    Each technique builds skill by improving concentration, focus, and balance. 
                    Master Yang has always been a patient and exceptional teacher. 
                    By the time Master Yang was 24, he was lecturing and teaching these rare traditional techniques to other students and senior instruct Kung Jung Mu Sul is a traditional Korean martial art, which means imperial palace martial arts. 
                    It is a diverse system that includes hard and soft elements, joint locks, throws, weapons and ki development. 
                    Hundreds of years ago the guards for the royal family practiced Kung Jung Mu Sul and some of the best martial artists in Korea were invited to the palace to train the guards. 
                    This special training gave the guards many different ways to deal with any situation that developed.
                </p>
            </section>
            <section className="grand-master-yang">
                <p className="grand-master-yang--txt">
                    In 1977, Master Yang traveled to Iran and was invited to teach martial arts and self-defense to the Teheran University in Iran.
                    <br />
                    <br />
                    In 1978, Master Yang traveled to the United States and four years later founded Yang’s Martial Arts School. 
                    Over the years Grand Master Yang has had more than 3500 students and has graduated only 136 Black Belts.
                    <br />
                    <br />
                    In 1985 Master Yang wrote his first book “Zen and Martial Arts in Daily Life”. 
                    The focus of the book is how we can incorporate the spiritual elements of martial arts into maintaining physical, mental, and spiritual health. 
                    There are several examples and parables throughout the book which illustrate these elements. 
                    In 1990, Master Yang integrated all the elements of his martial arts training into an integrated system he calls “Sun Moo Do”. 
                    The system incorporates the elements of Hapkido, Tae Kwon Do, and Kung Jung Mu Sul, and Ki Meditation and breathing techniques into a balanced and comprehensive martial arts system. 
                    Since moving to the United States, Master Yang has touched the lives of many students and friends in Montgomery and Bucks County, Pennsylvania. 
                    He has given his time and made contributions that have positively impacted the community and he has been recognized over the years for contributions. 
                    He is also a creative force for his home country of South Korea and has been active at generating business opportunities and supporting cooperation through the America, Korea, and Chinese Leader’s Society. 
                    Grand Master Yang is devoted to his family, students, friends and country.
                </p>
                <img alt="Grand Master Yang in sword ready stance" className="grand-master-yang--img" src={GrandMasterYangSword}></img>
            </section>
        </div>
    );
}