import './faq.scss';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { useEffect } from 'react';
import { LaptopWindows } from '@material-ui/icons';

const questions = [
    { questionText: 'What are Martial Arts?', answerText: 'Martial arts are the systems and traditions of combat practices, which are practiced for a variety of reasons: self-defense, competition, physical health and fitness, entertainment, as well as mental, physical, and spiritual development.' },
    { questionText: 'What are the benefits?', answerText: 'The benefits of Martial Arts are: positive attitude, improved self-discipline, self-defense, concentration, coordination, weight control, increased physical fitness, higher academic achievement, and improved leadership qualities.' },
    { questionText: 'What are the mental benefits?', answerText: 'Martial Arts strengthens the will and fortifies a person against mental obstacles, weaknesses, disappointments, and the discouragements of life. With this resilience, he or she may enjoy greater health, peace, and happiness.' },
    { questionText: 'What are the physical benefits?', answerText: 'No one is born with a strong body and soul. Like a moving machine needs proper care and lubrication in order to function, a person must know how to develop and maintain his or her health with physical and mental conditioning.' },
    { questionText: 'How fast can I learn?', answerText: 'You will learn martial arts and self-defense techniques in your first lesson. With personal effort and commitment, progress will be swift and consistent. There is no reason why a beginner cannot achieve a black belt within a few years.' },
    { questionText: 'How often should I train?', answerText: 'Students should attend at least two or three formal classes per week, and it is recommended that they train a short time each day at home.' },
    { questionText: 'What kinds of classes are available?', answerText: "Adult (ages 13+) classes, women's self-defense classes, Junior (ages 4-12) classes, private classes. Special classes, such as law enforcement, private groups, high school and college self-defense training, are available by appointment." },
    { questionText: 'When can I start?', answerText: "You can begin training at any class session. As a beginning student, you will be provided with special, introductory, private lessons. After that, you will train with other students in your class." },
    { questionText: 'How much does it cost?', answerText: "Payments occur on a monthly basis with prices determined on a case-by-case basis and vary according to the classes wanted. For a quotation of a fee that will apply to your specific case, please contact Yangs Martial arts by phone or Email to make an appointment." }
]

export const FAQ = () => {
    useEffect(() => {
        return () => window.onresize = undefined;
    }, [])

    window.onresize = () => {
        questions.forEach((q, i) => {
            const answerId = `question_${i}_answer`;
            const answerTextId = `${answerId}_text`;
            const answerEl = document.getElementById(answerId);
            if (answerEl.offsetHeight > 0) {
                const answerTextEl = document.getElementById(answerTextId);
                answerEl.style.height = `${answerTextEl.offsetHeight}px`;
            }
        })
    }

    const renderQuestion = (props) => {
        const iconId = `${props.id}_icon`;
        const answerId = `${props.id}_answer`;
        const answerTextId = `${answerId}_text`;
        return (
            <section id={props.id} className={`faq--container`}>
                <div className="faq--question" onClick={(e) => {
                    const iconEl = document.getElementById(iconId);
                    const answerEl = document.getElementById(answerId);
                    const answerTextEl = document.getElementById(answerTextId);
                    if (Array.from(iconEl.classList).includes('faq--question--closed')) {
                        iconEl.classList.remove('faq--question--closed');
                        iconEl.classList.add('faq--question--open');
                        answerEl.classList.remove('faq-answer--closed');
                        answerTextEl.classList.add('faq-answer-text--open');
                        answerTextEl.classList.remove('faq-answer-text--closed');
                        answerEl.style.height = `${answerTextEl.offsetHeight}px`;
                    } else {
                        iconEl.classList.add('faq--question--closed');
                        iconEl.classList.remove('faq--question--open');
                        answerEl.classList.add('faq-answer--closed');
                        answerTextEl.classList.add('faq-answer-text--closed');
                        answerTextEl.classList.remove('faq-answer-text--open');
                        answerEl.style.height = `0px`;
                    }
                }}>
                    <h4>{props.questionText}</h4>
                    <AddCircleOutlineIcon id={iconId} className={'faq--question--icon faq--question--closed'} fontSize="large" />
                </div>
                <div id={answerId} className="faq-answer faq-answer--closed" style={{ height: '0px' }}>
                    <p id={answerTextId} className="faq-answer-text faq-answer-text--closed">{props.answerText}</p>
                </div>
            </section>
        )
    }

    return (
        <div className="faq">
            <h2 className="header--section">
                FAQ
            </h2>
            <div className="faq--questions">
                {questions.map((q, i) => renderQuestion({ id: `question_${i}`, questionText: q.questionText, answerText: q.answerText }))}
            </div>
        </div>
    );
}