import { s3APIUrl, ymasAPIUrl } from '../config';

export const S3Service = {
    getObjectsFromAlbum: async (albumPath, maxObjects = 20, continuationToken = '') => {
        try {
            const req = await fetch(`${ymasAPIUrl}/gallery/albums/${albumPath}`);
            // const req = await fetch(`${s3APIUrl}?list-type=2&prefix=${albumPath}&start-after=${albumPath}/&max-keys=${maxObjects}${continuationToken ? `&continuation-token=${encodeURIComponent(continuationToken)}` : ''}`);
            if (req.ok) {
                const res = await req.json();
                // console.log(res);
                return res;
            } else {
                throw new Error(await req.json());
            }
        } catch(e) {
            throw e;
        }
    },
    getAlbums: async (path) => {
        try {
            // const req = await fetch(`${s3APIUrl}?list-type=2&prefix=${path}/&delimiter=/`);
            const req = await fetch(`${ymasAPIUrl}/gallery/albums`);
            if (req.ok) {
                // const res = await req.text();
                // return Array.from(new DOMParser().parseFromString(res, 'text/html').querySelectorAll('CommonPrefixes Prefix'));
                const res = await req.json();
                return res;
            } else {
                throw new Error(await req.json());
            }
        } catch(e) {
            throw e;
        }
    }
}