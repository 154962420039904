import { Icon } from './Icon';

export const Goal = (props) => {
    return  (
        <Icon 
            className={props.className}
            viewBox="0 0 100 100"
        >
            <switch>
                <foreignObject requiredExtensions="http://ns.adobe.com/AdobeIllustrator/10.0/" x="0" y="0" width="1" height="1"></foreignObject>
                <g>
                    <g>
                        <path d="M20.4,54l-2.8,18.1h8.1L28,56.8l-5.3-1.7C21.9,54.9,21.1,54.5,20.4,54z"></path><path d="M13.1,44.5c0.3,0.1,0.6,0.1,0.9,0.1c1.4,0,2.8-0.9,3.2-2.4l1.8-6.4l3.5-1.7l-1.3,12.7c-0.2,1.9,0.9,3.6,2.8,4.2l11,3.5     l0.1,9.8h8l-0.2-12.7c0-1.7-1.1-3.2-2.8-3.7l-7-2.2l2-8.8l3.3,4.3c0.8,1,2.2,1.5,3.5,1.2l7.6-1.9c1.8-0.5,2.8-2.3,2.4-4     c-0.5-1.8-2.3-2.8-4-2.4l-5.5,1.4L38.3,30c-0.1-0.2-0.3-0.3-0.4-0.5c-1.1-1-2.5-1.7-3.9-2l-6.2-1.3c-1.8-0.4-3.8-0.1-5.5,0.7     l-7.4,3.6c-0.9,0.4-1.5,1.2-1.8,2.1l-2.2,7.8C10.3,42.1,11.4,43.9,13.1,44.5z"></path><path d="M95,52.3H66.9c-1.4,0-2.5,1.1-2.5,2.5v5.4H50.3c-1.4,0-2.5,1.1-2.5,2.5V68H33.7c-1.4,0-2.5,1.1-2.5,2.5v5.4H17.1     c-1.4,0-2.5,1.1-2.5,2.5v6.8H5c-1.4,0-2.5,1.1-2.5,2.5c0,1.4,1.1,2.5,2.5,2.5h12.1c1.4,0,2.5-1.1,2.5-2.5v-6.8h14.1     c1.4,0,2.5-1.1,2.5-2.5V73h14.1c1.4,0,2.5-1.1,2.5-2.5v-5.4h14.1c1.4,0,2.5-1.1,2.5-2.5v-5.4H95c1.4,0,2.5-1.1,2.5-2.5     S96.4,52.3,95,52.3z"></path><circle cx="33.5" cy="18.2" r="7"></circle><path d="M72.4,19.6c0.3,0.8,1.1,1.2,1.9,1.2c0.3,0,0.5,0,0.8-0.2c1-0.4,1.5-1.6,1.1-2.6l-2.1-5c-0.4-1-1.6-1.5-2.6-1.1     c-1,0.4-1.5,1.6-1.1,2.6L72.4,19.6z"></path><path d="M89.5,20.7c0.3,0.1,0.5,0.2,0.8,0.2c0.8,0,1.5-0.5,1.9-1.2l2.1-5c0.4-1-0.1-2.2-1.1-2.6c-1-0.4-2.2,0.1-2.6,1.1l-2.1,5     C87.9,19.1,88.4,20.3,89.5,20.7z"></path><path d="M82.3,19.3c1.1,0,2-0.9,2-2v-5.4c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2v5.4C80.3,18.3,81.2,19.3,82.3,19.3z"></path><path d="M74.6,37.7c0.6,0.5,0.8,1.2,0.6,1.9l-1.1,4.8c-0.4,1.7,1.4,3,2.9,2.1l4.2-2.6c0.6-0.4,1.4-0.4,2,0l4.2,2.6     c1.5,0.9,3.3-0.4,2.9-2.1l-1.1-4.8c-0.2-0.7,0.1-1.5,0.6-1.9l3.8-3.2c1.3-1.1,0.6-3.3-1.1-3.4l-5-0.4c-0.7-0.1-1.4-0.5-1.6-1.2     l-1.9-4.6c-0.7-1.6-2.9-1.6-3.6,0l-1.9,4.6c-0.3,0.7-0.9,1.1-1.6,1.2l-5,0.4c-1.7,0.1-2.4,2.3-1.1,3.4L74.6,37.7z"></path>
                    </g>
                </g>
                </switch>
        </Icon>
    );
}