import './class.scss';

export const Class = (props) => {
    return (
        <div className="class">
            <h2 className="header--sections">{props.name}</h2>
            <img className="class--img" alt={`Descriptive image for ${props.name} class at yangs martial arts school`} src={props.imgSrc} />
            <div className="class__div">
                {props.children}
            </div>
        </div>
    );
}