import { Class } from './Class/Class';
import ClassCorporate from "../../img/class_corporate.png"

export const CorporateTraining = () => (
    <Class name="Corporate Training" imgSrc={ClassCorporate}>
        <p>The cost of stress at every level in an organization is immense. Though stress is present at all levels of an organization, the higher the position level, the higher the cost of this stress. This is because, the higher the position level, the higher the stakes. Examples of the costs of employees’ stress to a business can include lost time, lack of concentration, poor judgements, degradation of workplace relationships and cooperation, and loss of creativity.</p>
        <br />
        <p>In comparison to the cost of stress to a business, the price of a powerful corporate stress management program comprising martial arts exercise with special breathing, meditation, Ki training and stretching exercises is indeed quite small, and in fact will produce a dramatic return on investment for the business. These activities, combined with learning how to make the best use of our natural mental energies for quieting the mind, self-healing, and health maintenance, are the key to optimizing physical and emotional health resulting in a higher quality work- and home-life.</p>
        <br />
        <p>Typically stress occurs when people are faced with responsibilities that are emotionally and physically overwhelming and/or tasks perceived to be far out of their control and almost impossible to complete within expected time periods. This description applies to corporate employees as well as to stay-at-home parents. In either case, it results in problems that negatively affect the corporate bottom line.</p>
        <br />
        <p>In our fast paced, competitive modern society, many people experience stress constantly because they are forever taking on more than they can accomplish. This is especially true during difficult economic times when companies are “running lean” where everyone is expected to do more in less time. Excessive eating, alcohol and drug abuse, marital difficulties, and irritability are some of the signs of stress.</p>
        <br />
        <p>The consequences of prolonged stress at work or at home are very serious emotional illness and, eventually, serious physical illness in the form of hypertension, heart attacks, strokes, other heart related diseases, cancer, and a variety of other maladies.</p>
        <br />
        <p>It is well known that employee stress causes many problems in the workplace. Although we would like it to be different, the reality of today’s business world results in increasing pressures to do more faster and with fewer resources. In order to improve employees’ well-being and the company’s bottom line, the issue of stress must be addressed.</p>
        <br />
        <p>Yang’s Martial Arts School offers a Stress Reduction Program for companies in the North Penn area. This program combines the elements of special breathing, meditation, and stretching exercises with Ki training to provide employees with the ability to effectively deal with pressures both inside and outside the workplace.</p>
    </Class>
);