import { Class } from './Class/Class';
import ClassSelfDefense from "../../img/class_selfdefense.png"

export const SelfDefense = () => (
    <Class name="Self Defense" imgSrc={ClassSelfDefense}>
        <p>What would you do if someone opens you car door or comes through your window and attempts a carjack?</p>
        <br />
        <p>How would you respond if someone grabs you in the parking lot?</p>
        <br />
        <p>Can you escape if someone attacks you from behind?</p>
        <br />
        <p>Knowing how to protect yourself can be a real life saver. Self-defense is not karate or martial arts training, it does not require years of study to perfect. You don't have to be an athlete to learn how to defend yourself. Self-defense skills are not only for those few times in your life you have a scary encounter. Self-defense should be an integral part of your living strategy. Whether or not you ever face an attacker, your benefits will extend into your daily living. Become more confident in your career, relationship and personal life, experience fewer moments of discomfort, fear and chills down the spine, increase your odds of walking away from an encounter, unharmed and develop skills you can use throughout your life.</p>
        <br />
        <p>Beginners will learn to counter real life-threatening situations. Current students gain the practice and speed that can save your life.</p>
        <br />
        <p>Classes taught by Grand Master Bong Pil Yang, 7th Degree Black Belt and assisted by men and women black belts and higher belts.</p>
    </Class>
);