import './about.scss';
import { useHistory } from 'react-router-dom';
import { instructors } from '../../config/instructors';
import { InfoCard } from '../Common/InfoCard/InfoCard';
import AboutTeamwork from '../../img/about_teamwork.png'
import AboutTeamworkLrg from '../../img/about_teamwork-lrg.png'
import AboutMasterYang from '../../img/about_master_yang.png'
import AboutMasterYangLrg from '../../img/about_master_yang-lrg.png'
import AboutFocus from '../../img/about_focus.png'
import AboutFocusLrg from '../../img/about_focus-lrg.png'
import AboutMasterDesc from '../../img/about_grandmaster_desc.png'

export const About = () => {
    const history = useHistory();
    return (
        <div>
            <section className="about-section">
                <h2 className="header--section">{'Our Mission & Vision'}</h2>
                <div className="about-section__div">
                    <div className="about-mission--photos composition">
                        {/* <div className="composition"> */}
                            <img alt="Teamwork at Yang's Martial Arts School, Inc."
                                srcSet={`${AboutTeamwork} 300w, ${AboutTeamworkLrg} 1000w`}
                                sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px"
                                className="composition__photo composition__photo--p1"
                                src={AboutTeamworkLrg} />

                            <img alt="Student focusing on technique at Yang's Martial Arts School, Inc."
                                srcSet={`${AboutFocus} 300w, ${AboutFocusLrg} 1000w`}
                                sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px"
                                className="composition__photo composition__photo--p2"
                                src={AboutFocusLrg} />
                                
                            <img alt="Grand Master Yang giving speach."
                                srcSet={`${AboutMasterYang} 300w, ${AboutMasterYangLrg} 1000w`}
                                sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px"
                                className="composition__photo composition__photo--p3"
                                src={AboutMasterYangLrg}/>
                        {/* </div> */}
                    </div>
                    <div className="about-mission--desc">
                        <p>Under the instruction of Grand Master Bong Pil Yang, we have been in the Lansdale/North Wales area for over 34 years. Grand Master Yang has been training in Martial Arts for over 50 years. At Yang's Martial Arts School, we practice Sun Moo Do, which combines the arts of Tae Kwon Do, Hap Ki Do, Kung Jung Moo Sul, Gumdo, Meditation and Ki Training.</p>
                        <p>Our goal is to train each of our students with the hope of reaching their true mental, physical, and spiritual potential. Our students reach a level of self confidence and awareness that is only attainable through Martial Arts taught by a true Grand Master. Many of our techniques have been handed down from generation to generation over the centuries. It is this belief in the next generation that makes Yang's Martial Arts School stand apart.</p>
                    </div>
                </div>
            </section>
            <section className="about-section">
                <h2 className="header--section">{'We Teach Multiple Disciplines'}</h2>
                <div className="info-card--container">
                    <InfoCard id= 'infoCard_1' headerText= "Hyung (Practice Pattern)" text={<p className="about-info-card-text">The martial artist seeks to develop and maintain balance and equilibrium, coordination, speed agility, flexibility, power, strength, endurance and breath control.</p>}/>
                    <InfoCard id= 'infoCard_2' headerText= "Isometrics and Ki Training" text={<p className="about-info-card-text">The Ki energy can be described as a unique life energy that ties the body and mind. One of the most important facets of the Martial Arts is to produce "KI" (the spirit or internal strength of the mind).  When KI is present, you have control over your hands and feet, will be able to build defensive power, and enjoy greater energy.  KI distributes power throughout your entire body.</p>}/>
                    <InfoCard id= 'infoCard_3' headerText= "Weapons" text={
                        <div className="about-info-card-text">
                            <b>There are five reasons we train in the use of martial arts weapons:</b>
                            <ol>
                                <li>Concentration</li> 
                                <li>Coordination</li> 
                                <li>Tradition</li>
                                <li>Self-defense</li>
                                <li>Flexibility</li>
                            </ol>
                        </div>
                    }/>
                    <InfoCard id= 'infoCard_4' headerText= "Tae Kwon Do" text={
                        <div className="about-info-card-text">
                            <p><b>Tae:</b> To jump kick or smash with foot.</p>
                            <p><b>Kown:</b> To punch or destroy with hand, chiefly with fist.</p>
                            <p><b>Do:</b> An art, way or method.</p>
                        </div>
                    }/>
                    <InfoCard id= 'infoCard_5' headerText= "Hap Ki Do" text={
                        <div className="about-info-card-text">
                            <p><b>Yoo (Softness):</b> To watch opponent’s movement with soft flowing, like water.</p>
                            <p><b>Woon (Circling):</b>  To redirect your opponent’s power away from you.</p>
                            <p><b>Hwa (Harmony):</b> To harmonize your movements with your opponent’s movement.</p>
                        </div>
                    }/>
                    <InfoCard id= 'infoCard_6' headerText= "Kung Jung Mu Sul" text= {<p className="about-info-card-text">Royal Court Martial Arts, is a secret and traditional martial arts of Korea. It is reserved for the guards at the Imperial Place and Royal family.</p>} />
                </div>
            </section>
            <section className="about-section">
                <h2 className="header--section">{'Training from a true grandmaster'}</h2>
                <div className="about-grandmaster about-section__div">
                    <div className="about-grandmaster--details">
                        <h3>Over 50 Years of Training and Experience</h3>
                        <p>Grand Master Yang has been training in Martial Arts since the age of 13 in his native Korea. Many of the principles and teachings from Master Yang have been handed down over hundreds of years from Grand Masters and Great Masters. All of our instructors are Third Dan Black Belts or higher and have been at Master Yang's for seven or more years!</p>
                        <h3>Challenge The Mind Not Just The Body</h3>
                        <p>Our goal is to train each of our students with the hope of reaching their true mental, physical, and spiritual potential. Our students reach a level of self confidence and awareness that is only attainable through Martial Arts taught by a true Grand Master. Many of our techniques have been handed down from generation to generation over the centuries. It is this belief in the next generation that makes Yang's Martial Arts School stand apart.</p>
                    </div>
                    <div className="about-grandmaster--desc">
                        {renderInstructorCard({ 
                            onClick: () => history.push('/about/grandmaster'),
                            image: AboutMasterDesc,
                            headerText: 'Grand Master Bong Pil Yang', 
                            subHeaderText: 'Owner / Founder',
                            text: 'Since arriving in the United States, Grand Master Yang has strived to help his community, from helping senior citizens and handicapped children to raising money for the bulletproof vests for the local police.' 
                        })}
                    </div>
                </div>
            </section>
            <section className="about-section">
                <h2 className="header--section">{'Instructors'}</h2>
                <div className="about-instructors about-section__div">
                    {instructors.map((info, i) => (
                       renderInstructorCard({ 
                            onClick: () => history.push(info.href),
                            image: info.image, 
                            headerText: info.name, 
                            subHeaderText: info.role,
                            text: info.shortDescription,
                            id: `instructor_${i}`
                        }) 
                    ))}
                </div>
            </section>
        </div>
    );
}

const renderInstructorCard = (props) => {
    return (
        <div id={props.id} key={props.id} className="card about-card" onClick={props.onClick}>
            <img alt={`Instructor ${props.headerText} at Yang's Martial Arts School, Inc.`} className="about-card--photo" src={props.image}></img>
            <h3 className="about-card--header">{props.headerText}</h3>
            <h4 className="about-card--subheader">{props.subHeaderText}</h4>
            <p className="about-card--text">{props.text}</p>
        </div>
    );
}